.contact {
    box-sizing: border-box;
}

.contact span {
    color: var(--blue);
}

.contact h2 {
    margin-bottom: 25px;
}

.contact > p {
    margin: 20px 0 0 0;
    font-size: 25px;
}

@media(max-width: 1000px) {
    .contact > p {
        font-size: 18px;
    }
}