.projects {
    min-height: 50vh;
    box-sizing: border-box;
    z-index: 2;
    position: relative;
}

.projects--container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 30px auto;
    max-width: 1800px;
}