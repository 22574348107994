.project {
    width: calc(33.3% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    padding: 25px;
    box-sizing: border-box;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.05);
}

.project img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 3px solid #666;
}

.project--body {
    padding-top: 10px;
    font-weight: 200;
}

.project--body > h3 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 12px;
}

.project--body > p {
    font-size: 20px;
    margin-bottom: 15px;
}

@media(max-width: 1000px) {
    .project {
        width: calc(50% - 20px);
        padding: 15px;
    }

    .project--body > h3 {
        font-size: 21px;
    }

    .project--body > p {
        font-size: 18px;
    }
}

@media(max-width: 600px) {
    .project {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 15px;
    }
}