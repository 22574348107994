.about {
    box-sizing: border-box;
}

.about--section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 20px 0 60px 0;
}

.about--text > h3 {
    color: var(--blue);
    font-size: 29px;
    margin-bottom: 15px;
    font-weight: 600;
}

.about--text > p {
    font-size: 22px;
    margin-bottom: 9px;
    font-weight: 200;
}

.about--text a:link,
.about--text a:visited {
    color: var(--blue);
}

.about--text a:active,
.about--text a:hover {
    color: var(--light-blue);
}

.profile-picture {
    width: 160px;
    border-radius: 50%;
    margin-bottom: 5px;
    border: 4px solid white;
}

.about--text {
    width: 80%;
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 10px;
}

@media(max-width: 1000px) {
    .about--text > p {
        font-size: 18px;
    }

    .about--text > h3 {
        font-size: 22px;
    }

    .profile-picture {
        width: 120px;
    }

    .about--section {
        margin: 15px 0 15px 0;
    }
}