.hero {
    height: 50vh;
    position: relative;
    overflow: hidden;
}

.hero--content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 19px;
    text-align: center;
}

.hero--content h1 {
    font-size: 80px;
    line-height: 70px;
    margin-bottom: 40px;
}

.hero--highlight {
    color: var(--blue);
    display: block;
}

.hero img {
    width: 120%;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media(max-width: 1000px) {
    .hero img {
        width: 150%;
    }

    .hero--content {
        width: 50%;
    }

    .hero--content h1 {
        font-size: 65px;
        line-height: 55px;
    }
}

@media(max-width: 750px) {
    .hero img {
        display: none;
    }
}